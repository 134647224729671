import Axios from 'axios';

import { API_URL } from '@/constants';

export function ensureHttpsProtocol(url: string) {
  if (url.startsWith('http:')) {
    return url.replace(/^http:/, 'https:');
  } else {
    return url;
  }
}

const api = Axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

export default api;
