const items = [
  {
    title: 'Wykształcenie',
    desc: 'Z wykształcenia jestem Trenerką Biznesu oraz certyfikowaną Trenerką Mentalną z 8-letnią praktyką warsztatową dla grup i osób indywidualnych. Jestem bacznym obserwatorem, praktycznym i merytorycznym szkoleniowcem, komunikatywnym i pozytywnie podchodzącym do życia i ludzi człowiekiem.',
    svg: (
      <svg
        width="173"
        height="141"
        viewBox="0 0 173 141"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="70.5"
          cy="70.5"
          r="69"
          stroke="url(#paint0_linear_79_9)"
          strokeWidth="3"
        />
        <circle
          cx="102.5"
          cy="70.5"
          r="69"
          stroke="url(#paint1_linear_79_9)"
          strokeWidth="3"
        />
        <g clipPath="url(#clip0_79_9)">
          <path
            d="M103.589 68.7803C103.286 68.4648 102.818 68.3791 102.419 68.5628C102.024 68.7496 101.789 69.1661 101.838 69.601C102.334 74.0935 100.254 75.3889 97.8473 76.8895C95.8873 78.1083 93.6671 79.4894 93.6671 82.76C93.6671 85.2191 94.7788 87.4026 96.4967 88.9001C95.2381 90.2751 93.7498 91.396 92.4115 92.1401C91.6704 92.5505 91.4071 93.4845 91.8174 94.2226C92.2308 94.9667 93.1679 95.224 93.8998 94.8167C95.7097 93.8122 97.6451 92.3239 99.2161 90.4619C100.812 88.4591 101.831 85.8622 101.831 82.757C101.831 82.757 103.154 87.8191 102.377 90.8662C106.593 90.5661 109.944 87.0719 109.928 82.9377C109.974 82.6774 110.982 76.4668 103.589 68.7803Z"
            fill="#4396FF"
          />
          <path
            d="M107.938 49.7658C97.2196 43.7421 89.9709 45.947 85.4998 49.4382C81.0287 45.947 73.7801 43.7421 63.0617 49.7658C61.797 50.4794 61.0007 51.8268 61.0007 53.3182V84.1656C61.0007 85.4304 61.5734 86.6155 62.5717 87.3903C63.5731 88.1651 64.8379 88.4315 66.0629 88.1253C73.6178 86.2266 79.865 86.4103 84.6209 88.6765C84.9057 88.8204 85.1936 88.8817 85.4998 88.8817C85.8061 88.8817 86.0909 88.8204 86.3787 88.6765C87.6435 88.0855 89.0338 87.6139 90.5038 87.3076C89.9923 86.0827 89.6861 84.7352 89.6065 83.3265C88.8929 83.4705 88.2192 83.6542 87.5455 83.8563V53.0946C92.3228 49.0125 98.3465 49.0523 105.92 53.3182V65.3442C106.266 65.5677 106.594 65.8556 106.878 66.1618C108.125 67.4695 109.145 68.7557 110.002 69.999V53.3182C109.999 51.8483 109.203 50.4794 107.938 49.7658ZM83.4572 83.8808C81.0471 83.1244 78.4135 82.7569 75.5563 82.7569C72.3316 82.7569 68.8404 83.2255 65.0829 84.1656L65.0645 53.3182C72.6592 49.0523 78.6829 49.0094 83.4603 53.0946L83.4572 83.8808Z"
            fill="#4396FF"
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_79_9"
            x1="183"
            y1="-43"
            x2="214.864"
            y2="51.7195"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.270846" stopColor="#4396FF" />
            <stop offset="0.569976" stopColor="#F3E0F1" />
            <stop offset="1" stopColor="#DFA8B4" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_79_9"
            x1="215"
            y1="-43"
            x2="246.864"
            y2="51.7195"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.270846" stopColor="#4396FF" />
            <stop offset="0.569976" stopColor="#F3E0F1" />
            <stop offset="1" stopColor="#DFA8B4" />
          </linearGradient>
          <clipPath id="clip0_79_9">
            <rect
              width="82"
              height="82"
              fill="white"
              transform="translate(45 30)"
            />
          </clipPath>
        </defs>
      </svg>
    ),
  },
  {
    title: 'Doświadczenie akademickie',
    desc: 'Od 7 lat związana jestem zawodowo z uczelniami wyższymi zarówno w Bydgoszczy jak i we Wrocławiu, gdzie prowadzę praktyczne zajęcia dla studentów na kierunkach Biznes, Zarządzanie, HR.',
    svg: (
      <svg
        width="173"
        height="141"
        viewBox="0 0 173 141"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_107_4)">
          <path
            d="M86.5 60.3439C87.9023 60.3439 89.0391 59.2071 89.0391 57.8048C89.0391 56.4025 87.9023 55.2657 86.5 55.2657C85.0977 55.2657 83.9609 56.4025 83.9609 57.8048C83.9609 59.2071 85.0977 60.3439 86.5 60.3439Z"
            fill="#4396FF"
          />
          <path
            d="M65.7533 60.1642C64.7831 59.776 64.1562 58.85 64.1562 57.8048C64.1562 56.7596 64.7831 55.8336 65.7533 55.4455C65.758 55.4437 84.6803 47.7359 90.1816 45.4904C89.1746 45.0794 88.4043 44.6017 88.4043 44.6017C87.8109 44.3663 86.5856 43.4776 85.9922 43.713C85.9922 43.713 58.1416 55.4432 58.1361 55.4455C57.166 55.8336 56.5391 56.7596 56.5391 57.8048C56.5391 58.85 57.166 59.776 58.1361 60.1641C58.1416 60.1664 85.2305 71.1349 85.2305 71.1349C85.8238 71.3703 87.4301 71.1164 88.0234 70.881C88.0234 70.881 89.0477 70.5302 90.0547 70.1193C84.5532 67.8737 65.7579 60.166 65.7533 60.1642Z"
            fill="white"
          />
          <path
            d="M114.22 50.7344C114.22 50.7344 89.3333 40.5764 89.3276 40.5742C87.571 39.8719 85.5371 39.8288 83.6724 40.5742C83.6667 40.5765 58.7795 50.7344 58.7795 50.7344C55.8757 51.9005 54 54.6747 54 57.8048C54 60.935 55.8757 63.7091 58.7795 64.8752C64.6671 67.2783 83.6593 75.0303 83.6724 75.0354C85.429 75.7377 87.4629 75.7809 89.3276 75.0354C89.3403 75.0304 88.7071 75.2888 114.22 64.8752C117.124 63.7091 119 60.935 119 57.8048C119 54.6747 117.124 51.9005 114.22 50.7344ZM112.325 60.1642C112.319 60.1664 87.436 70.3229 87.436 70.3229C86.8426 70.5583 86.1574 70.5583 85.564 70.3229C85.564 70.3229 60.6807 60.1665 60.6752 60.1642C59.705 59.776 59.0781 58.85 59.0781 57.8048C59.0781 56.7596 59.705 55.8336 60.6752 55.4455C60.6807 55.4434 85.564 45.2869 85.564 45.2869C86.1574 45.0515 86.8426 45.0515 87.436 45.2869C87.436 45.2869 112.319 55.4432 112.325 55.4455C113.295 55.8336 113.922 56.7597 113.922 57.8048C113.922 58.8499 113.295 59.776 112.325 60.1642Z"
            fill="#4396FF"
          />
          <path
            d="M71.2656 75.7365C69.8633 75.7365 68.7266 76.8732 68.7266 78.2755V79.1345C68.7266 82.2673 70.6061 85.0434 73.5149 86.2068L83.6724 90.2698C85.4478 90.9796 87.4811 91.008 89.328 90.2697L99.4851 86.2068C102.394 85.0434 104.273 82.2673 104.273 79.1345V78.2755C104.273 76.8732 103.137 75.7365 101.734 75.7365C100.332 75.7365 99.1953 76.8732 99.1953 78.2755V79.1345C99.1953 80.1788 98.5688 81.1042 97.5993 81.492L87.4426 85.5546C86.8454 85.7934 86.1543 85.7932 85.558 85.5547L75.4009 81.4919C74.4313 81.104 73.8048 80.1787 73.8048 79.1343V78.2754C73.8047 76.8732 72.6679 75.7365 71.2656 75.7365Z"
            fill="#4396FF"
          />
          <path
            d="M113.922 92.3V74.213C113.922 72.8107 112.785 71.674 111.383 71.674C109.981 71.674 108.844 72.8107 108.844 74.213V92.3L104.509 96.6345C103.518 97.6261 103.518 99.2337 104.509 100.225C105.501 101.217 107.109 101.217 108.1 100.225L111.383 96.9424L114.666 100.225C115.657 101.217 117.265 101.217 118.256 100.225C119.248 99.2336 119.248 97.626 118.256 96.6345L113.922 92.3Z"
            fill="#4396FF"
          />
        </g>
        <circle
          cx="70.5"
          cy="70.5"
          r="69"
          stroke="url(#paint0_linear_107_4)"
          strokeWidth="3"
        />
        <circle
          cx="102.5"
          cy="70.5"
          r="69"
          stroke="url(#paint1_linear_107_4)"
          strokeWidth="3"
        />
        <defs>
          <linearGradient
            id="paint0_linear_107_4"
            x1="183"
            y1="-43"
            x2="214.864"
            y2="51.7195"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.270846" stopColor="#4396FF" />
            <stop offset="0.569976" stopColor="#F3E0F1" />
            <stop offset="1" stopColor="#DFA8B4" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_107_4"
            x1="215"
            y1="-43"
            x2="246.864"
            y2="51.7195"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.270846" stopColor="#4396FF" />
            <stop offset="0.569976" stopColor="#F3E0F1" />
            <stop offset="1" stopColor="#DFA8B4" />
          </linearGradient>
          <clipPath id="clip0_107_4">
            <rect
              width="65"
              height="65"
              fill="white"
              transform="translate(54 38)"
            />
          </clipPath>
        </defs>
      </svg>
    ),
  },
  {
    title: 'Narzędzia',
    desc: 'W pracy zarówno warsztatowej, jak i indywidualnej, wykorzystuję narzędzia treningu mentalnego, które pomagają moim klientom wzmocnić pewność siebie, dokonać potrzebnych zmian i cieszyć się życiem.',
    svg: (
      <svg
        width="173"
        height="141"
        viewBox="0 0 173 141"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="70.5"
          cy="70.5"
          r="69"
          stroke="url(#paint0_linear_107_5)"
          strokeWidth="3"
        />
        <circle
          cx="102.5"
          cy="70.5"
          r="69"
          stroke="url(#paint1_linear_107_5)"
          strokeWidth="3"
        />
        <g clipPath="url(#clip0_107_5)">
          <path
            d="M77.8771 90.9067L77.8769 87.1752C77.8767 85.0315 76.9988 83.0049 75.5036 81.4687C71.1466 76.9921 68.8324 70.524 70.0966 63.5656C71.5343 55.6526 77.807 49.1548 85.6767 47.4969C86.1205 47.4034 86.5624 47.3261 87.0024 47.2615C84.6365 46.9059 82.1389 46.9585 79.5833 47.4969C71.7136 49.1549 65.4409 55.6527 64.0032 63.5657C62.7391 70.524 65.0533 76.9922 69.4102 81.4687C70.9054 83.0049 71.7834 85.0315 71.7835 87.1752L71.7837 90.9067C71.7838 93.1502 73.6024 94.9689 75.8459 94.9689H81.9393C79.6958 94.9689 77.8771 93.1502 77.8771 90.9067Z"
            fill="white"
          />
          <path
            d="M110.271 78.8901L106.891 68.7506C106.523 67.6449 106.343 66.4406 106.188 64.9724C105.523 58.7145 102.205 52.9896 97.0839 49.2657C91.9052 45.5001 85.5413 44.1661 79.1645 45.5095C70.4914 47.3367 63.5955 54.447 62.0049 63.2026C61.332 66.9065 61.5533 70.5599 62.6626 74.0614C63.7032 77.3461 65.5332 80.3974 67.9547 82.8854C69.1138 84.0763 69.7522 85.5999 69.7523 87.1754L69.7525 90.9069C69.7526 94.2667 72.4861 97.0001 75.8459 97.0001H96.1573C99.5172 97.0001 102.251 94.2666 102.251 90.9067V87.6569C102.251 86.5351 101.341 85.6258 100.22 85.6258H96.1573C95.0355 85.6258 94.1261 86.5351 94.1261 87.6569C94.1261 88.7787 95.0355 89.688 96.1573 89.688H98.1884V90.9067C98.1884 92.0267 97.2772 92.9378 96.1573 92.9378H75.8459C74.726 92.9378 73.8148 92.0267 73.8148 90.9067L73.8146 87.1753C73.8146 84.5356 72.7673 82.0058 70.8658 80.0522C66.6921 75.7638 64.9191 69.8871 66.0016 63.9288C67.3004 56.7798 72.9267 50.9751 80.002 49.4845C85.2397 48.3812 90.4578 49.4702 94.6948 52.5512C98.8883 55.6004 101.605 60.2841 102.148 65.4011C102.296 66.7965 102.499 68.4204 103.038 70.0352L105.526 77.5013H100.22C99.0978 77.5013 98.1885 78.4106 98.1885 79.5324C98.1885 80.6542 99.0978 81.5635 100.22 81.5635H108.344C108.997 81.5635 109.61 81.2497 109.992 80.72C110.374 80.1903 110.477 79.5094 110.271 78.8901Z"
            fill="#4396FF"
          />
          <path
            d="M83.9705 81.5634C81.2756 81.5634 78.9838 79.805 78.182 77.3751C75.7219 77.8921 73.0303 76.8323 71.6573 74.4545C70.3096 72.1198 70.6873 69.2545 72.392 67.3454C70.6873 65.4362 70.3097 62.5709 71.6573 60.2366C73.0287 57.8611 75.7193 56.7983 78.182 57.3157C78.9838 54.886 81.2755 53.1276 83.9704 53.1276C86.6653 53.1276 88.9571 54.886 89.7588 57.3157C92.2252 56.7973 94.9143 57.8645 96.2836 60.2367C97.6314 62.5711 97.2538 65.4363 95.5488 67.3455C97.2535 69.2546 97.6313 72.1198 96.2836 74.4545C95.4697 75.8641 94.1558 76.8723 92.5835 77.2936C91.6454 77.545 90.6826 77.5694 89.7588 77.3753C88.9573 79.805 86.6654 81.5634 83.9705 81.5634ZM80.9238 72.6226C81.5522 72.9854 81.9394 73.6559 81.9394 74.3815V75.47C81.9394 76.59 82.8505 77.5011 83.9705 77.5011C85.0905 77.5011 86.0016 76.59 86.0016 75.47V74.3815C86.0016 73.6559 86.3888 72.9853 87.0172 72.6226C87.6456 72.2597 88.4198 72.2597 89.0483 72.6226L89.991 73.1668C90.9632 73.728 92.2043 73.3955 92.7656 72.4234C93.3255 71.4536 92.992 70.2088 92.0221 69.6489L91.0795 69.1047C90.451 68.7418 90.0639 68.0713 90.0639 67.3456C90.0639 66.6199 90.451 65.9494 91.0795 65.5866L92.0221 65.0423C92.992 64.4823 93.3255 63.2376 92.7656 62.2678C92.2042 61.2955 90.9633 60.9627 89.9911 61.5243L89.0483 62.0686C88.4199 62.4315 87.6456 62.4315 87.0172 62.0686C86.3888 61.7058 86.0016 61.0353 86.0016 60.3097V59.2212C86.0016 58.1012 85.0905 57.1901 83.9705 57.1901C82.8505 57.1901 81.9394 58.1012 81.9394 59.2212V60.3097C81.9394 61.0353 81.5522 61.7059 80.9238 62.0686C80.2954 62.4315 79.5211 62.4315 78.8927 62.0686L77.95 61.5244C76.9778 60.963 75.7366 61.2954 75.1755 62.2677C74.6155 63.2377 74.9492 64.4823 75.919 65.0424L76.8615 65.5866C77.4899 65.9494 77.877 66.6199 77.877 67.3456C77.877 68.0713 77.4899 68.7418 76.8615 69.1047L75.9188 69.6489C74.949 70.2088 74.6155 71.4536 75.1755 72.4235C75.7354 73.3934 76.9802 73.7269 77.95 73.1668L78.8928 72.6226C79.5209 72.2598 80.2954 72.2597 80.9238 72.6226Z"
            fill="#4396FF"
          />
          <path
            d="M83.9705 69.3766C85.0922 69.3766 86.0016 68.4673 86.0016 67.3455C86.0016 66.2238 85.0922 65.3144 83.9705 65.3144C82.8487 65.3144 81.9393 66.2238 81.9393 67.3455C81.9393 68.4673 82.8487 69.3766 83.9705 69.3766Z"
            fill="#4396FF"
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_107_5"
            x1="183"
            y1="-43"
            x2="214.864"
            y2="51.7195"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.270846" stopColor="#4396FF" />
            <stop offset="0.569976" stopColor="#F3E0F1" />
            <stop offset="1" stopColor="#DFA8B4" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_107_5"
            x1="215"
            y1="-43"
            x2="246.864"
            y2="51.7195"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.270846" stopColor="#4396FF" />
            <stop offset="0.569976" stopColor="#F3E0F1" />
            <stop offset="1" stopColor="#DFA8B4" />
          </linearGradient>
          <clipPath id="clip0_107_5">
            <rect
              width="52"
              height="52"
              fill="white"
              transform="translate(60 45)"
            />
          </clipPath>
        </defs>
      </svg>
    ),
  },
];

export default function CertsSection() {
  return (
    <div className="flex flex-row justify-center items-start flex-wrap gap-8">
      {items.map((item, index) => (
        <div
          className="flex flex-col gap-4 items-center text-center max-w-[380px]"
          key={index}
        >
          {item.svg}
          <div className="text-black text-[25px] font-bold">{item.title}</div>
          <div className="text-[#454545] text-[16px]">{item.desc}</div>
        </div>
      ))}
    </div>
  );
}
