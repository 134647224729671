import { defaultStyles } from '@/utils/defaultStyles';

const items = [
  {
    title: 'Trening mentalny - spotkania indywidualne',
    desc: 'Jeśli potrzebujesz popracować nad swoimi celami, życiem lub chcesz coś zmienić, ale nie wiesz jak się za to zabrać to spotkania 1:1 w dogodnym czasie, w formie online lub stacjonarnej będą dla Ciebie dobrym wyborem',
    secondDesc: '',
    img: '/services-1.png',
  },
  {
    title: 'Mentalne warsztaty edukacyjne',
    desc: 'Prowadzę tematyczne warsztaty dostowane do potrzeb grupy (m.in. profilaktyka uzależnień, stres, emocje, fazy rozwoju, planowanie kariery zawodowej, wystąpienia publiczne)',
    secondDesc:
      'dla dzieci (przedszkola, klasy SP 1-3, klasy 4-6) młodzież (SP klasy 7-8, szkoły średnie, studenci) seniorzy (aktywizujące, kreatywne)',
    img: '/services-2.png',
  },
  {
    title: 'Mentalne szkolenia biznesowe',
    desc: 'Inwestycja w pracownika, to inwestycja w firmę. Nowe kompetencje, narzędzia i metody pozwolą Twoim pracownikom rozwijać naturalne zdolności, budować pewność siebie czy radzić sobie ze stresem.',
    secondDesc:
      'Firmy, które inwestują w podniesienie dobrostanu u pracowników, odnotowują wzrost zaangażowania, wydajności i lojalności.',
    img: '/services-3.png',
  },
];

export default function ServicesSection() {
  return (
    <section
      className={`flex flex-col justify-center items-center bg-white ${defaultStyles.paddingBig}`}
    >
      <div
        className={`flex flex-row flex-wrap gap-8 justify-center items-start ${defaultStyles.padding}`}
      >
        {items.map((item, index) => (
          <div
            key={index}
            className="flex flex-col gap-4 justify-center items-center max-w-[340px]"
          >
            <img className="max-w-[336px] max-h-[237px]" src={item.img} />
            <div className="font-bold text-[25px] text-black text-center">
              {item.title}
            </div>
            <div className="text-[16px] text-black text-center">
              {item.desc}
            </div>
            {item.secondDesc.length > 0 && (
              <div className="font-[600] text-[16px] text-[#454545] text-center">
                {item.secondDesc}
              </div>
            )}
          </div>
        ))}
      </div>
    </section>
  );
}
