import { defaultStyles } from '@/utils/defaultStyles';

function LineItem(props: { id: number; first: string; second: string }) {
  return (
    <div className="flex flex-row justify-between items-center gap-4 border border-solid border-l-0 border-t-0 border-r-0 pb-2 border-[#E1E1E1]">
      <div className="flex flex-row items-center gap-4">
        <svg
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="19.5" cy="20.5" r="19.5" fill="#4396FF" />
          <path
            d="M27.2513 20.7125L26.8038 19.4413L21.9763 24.2688C21.5038 24.74 20.8751 25 20.2088 25C19.5413 25 18.9138 24.74 18.4413 24.2675L14.9001 20.7262C14.4276 20.2537 14.1676 19.6263 14.1676 18.9588C14.1676 18.2913 14.4276 17.6638 14.9001 17.1913C15.3726 16.7188 16.0001 16.4588 16.6676 16.4588C17.3351 16.4588 17.9626 16.7188 18.4351 17.1913L20.2088 18.965L24.9626 14.2113L24.6426 13.3025L24.4951 12.4213C24.4013 11.8563 24.0301 11.3875 23.5001 11.1675C22.9688 10.9462 22.3751 11.015 21.9088 11.35L19.5926 13.0262L17.2513 11.3462C16.7813 11.015 16.1863 10.9487 15.6576 11.17C15.1326 11.39 14.7638 11.8575 14.6713 12.4188L14.2176 15.2413L11.3788 15.7125C10.8138 15.805 10.3463 16.1775 10.1263 16.7075C9.90631 17.2375 9.97381 17.8325 10.3088 18.2988L11.9851 20.615L10.3051 22.9563C9.97381 23.4263 9.90756 24.0225 10.1288 24.55C10.3488 25.075 10.8163 25.4438 11.3776 25.5363L14.2001 25.99L14.6726 28.8288C14.7651 29.3925 15.1338 29.86 15.6588 30.08C16.1876 30.3013 16.7838 30.235 17.2576 29.9L19.5738 28.2238L21.9088 29.9C22.1951 30.1038 22.5276 30.2088 22.8651 30.2088C23.0788 30.2088 23.2938 30.1663 23.5001 30.0813C24.0301 29.8613 24.4013 29.3938 24.4951 28.83L24.9488 26.0075L27.7876 25.5363C28.3513 25.4438 28.8176 25.075 29.0388 24.55C29.2601 24.0225 29.1938 23.4263 28.8588 22.9513L27.2513 20.7125Z"
            fill="white"
          />
          <path
            d="M20.2087 23.3337C19.995 23.3337 19.7825 23.2525 19.62 23.09L16.0787 19.5487C15.7537 19.2237 15.7537 18.6962 16.0787 18.37C16.4037 18.0437 16.9312 18.045 17.2575 18.37L20.21 21.3225L28.5787 12.9537C28.9037 12.6287 29.4312 12.6287 29.7575 12.9537C30.0837 13.2787 30.0825 13.8062 29.7575 14.1325L20.7987 23.0912C20.635 23.2512 20.4212 23.3337 20.2087 23.3337Z"
            fill="white"
          />
        </svg>

        <div className="text-[16px] text-black">
          {props.first}: <span className="font-bold">{props.second}</span>
        </div>
      </div>
      <button
        onClick={() => {
          if (props.first == 'Konsultacja') {
            document
              .getElementById('contact')!
              .scrollIntoView({ behavior: 'smooth' });
            return;
          } else {
            window.location.assign('/checkout/?id=' + props.id);
          }
        }}
        className="uppercase border border-solid border-[#4396FF] rounded-[60px] bg-transparent max-w-[140px] px-4 py-4 flex flex-row justify-between items-center cursor-pointer"
      >
        <div className="font-[600] text-[#4396FF] text-[16px] uppercase">
          Wybieram
        </div>
      </button>
    </div>
  );
}

const items = [
  {
    id: 0,
    first: 'Konsultacja',
    second: 'Bezpłatnie',
  },
  {
    id: 1,
    first: '1 spotkanie',
    second: '300 zł',
  },
  {
    id: 2,
    first: '3 spotkania',
    second: '750 zł',
  },
  {
    id: 3,
    first: 'Pakiet mentalny - 5 spotkań',
    second: '1200 zł',
  },
  {
    id: 4,
    first: 'Mentalne warsztaty edukacyjne',
    second: '350 zł',
  },
  {
    id: 5,
    first: 'Mentalne szkolenia biznesowe',
    second: '3500 zł',
  },
];

export default function PricingSection() {
  return (
    <>
      <section
        className={`flex flex-col-reverse xl:flex-row xl:justify-between xl:items-center xl:gap-4 gap-8 bg-white py-24 ${defaultStyles.paddingBig}`}
      >
        <img
          alt="Cennik"
          loading="lazy"
          className="xl:w-[50%] w-[60%] self-center xl:self-auto"
          src="/pricing.png"
        />
        <div className="flex flex-col gap-4 xl:w-[45%]">
          <div className="text-[35px] font-bold text-black">Oferta</div>
          {items.map((item, index) => (
            <LineItem {...item} key={index} />
          ))}
        </div>
      </section>
    </>
  );
}
