import createStyleForBackgroundImage from '@/utils/createStyleForBackgroundImage';
import { defaultStyles } from '@/utils/defaultStyles';

export default function CardsSection() {
  return (
    <section
      className={`flex flex-col bg-[#F1F6FA] ${defaultStyles.paddingBig}`}
    >
      <div className="bg-white rounded-b-[50px] flex flex-col justify-center items-center gap-8 py-12 h-[50px]" />

      <div className="flex flex-col gap-4 mt-12">
        <div className="flex flex-col xl:flex-row gap-4">
          <div
            style={createStyleForBackgroundImage('/gallery-1.png')}
            className="xl:w-[70%] h-[340px] rounded-[35px] px-4 py-8"
          />
          <div className="xl:w-[30%] h-[340px] flex flex-col gap-4 bg-[#4396FF] rounded-[35px] px-4 py-8">
            <svg
              width="120"
              height="98"
              viewBox="0 0 120 98"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="48.8596"
                cy="48.8596"
                r="47.3596"
                stroke="url(#paint0_linear_80_166)"
                strokeWidth="3"
              />
              <circle
                cx="71.0371"
                cy="48.8596"
                r="47.3596"
                stroke="url(#paint1_linear_80_166)"
                strokeWidth="3"
              />
              <g clipPath="url(#clip0_80_166)">
                <path
                  d="M65.4297 61.7923L46.4274 42.79C43.2935 39.6561 42.6552 34.9716 44.5125 31.2061C43.9046 31.3382 43.326 31.6396 42.8535 32.1121C38.918 36.0476 38.918 42.4284 42.8535 46.3638L61.8558 65.3662C65.7913 69.3017 72.1721 69.3017 76.1075 65.3662C76.5801 64.8937 76.8815 64.3151 77.0136 63.7072C73.2481 65.5644 68.5637 64.9263 65.4297 61.7923Z"
                  fill="#FF99BE"
                />
                <path
                  d="M75.1732 32.0388C75.1732 29.2604 72.9128 27 70.1345 27C67.3561 27 65.0957 29.2604 65.0957 32.0388C65.0957 34.8171 67.3561 37.0775 70.1345 37.0775C72.9128 37.0775 75.1732 34.8171 75.1732 32.0388ZM70.1345 33.7183C69.2083 33.7183 68.4549 32.9649 68.4549 32.0388C68.4549 31.1126 69.2083 30.3592 70.1345 30.3592C71.0606 30.3592 71.814 31.1126 71.814 32.0388C71.814 32.9649 71.0606 33.7183 70.1345 33.7183Z"
                  fill="white"
                />
                <path
                  d="M78.318 44.6542L75.9982 40.5149C74.8101 38.3946 72.5632 37.0774 70.1345 37.0774C67.7057 37.0774 65.4588 38.3946 64.2707 40.5149L61.9509 44.6542C61.3243 45.7724 62.1343 47.1549 63.4161 47.1549H76.8528C78.1346 47.1549 78.9446 45.7724 78.318 44.6542ZM66.2827 43.7957L67.2011 42.1571C67.2011 42.1571 67.2011 42.1571 67.2011 42.157C67.7957 41.0958 68.9198 40.4366 70.1345 40.4366C71.3491 40.4366 72.4732 41.0958 73.0679 42.1571L73.9862 43.7957H66.2827Z"
                  fill="white"
                />
                <path
                  d="M53.5423 35.675L48.7917 30.9244C46.8271 28.9598 43.6304 28.9598 41.6659 30.9244C37.0818 35.5085 37.0818 42.9674 41.6659 47.5515L60.6682 66.5538C65.2631 71.1488 72.7003 71.1488 77.2952 66.5538C79.2646 64.5845 79.2646 61.3973 77.2952 59.4279L72.5447 54.6774C70.5801 52.7127 67.3834 52.7127 65.4188 54.6774C64.7639 55.3323 63.6984 55.3323 63.0435 54.6774L53.5424 45.1762C52.8875 44.5214 52.8875 43.4558 53.5424 42.801C55.5069 40.8363 55.5069 37.6396 53.5423 35.675ZM60.6682 57.0527C62.633 59.0174 65.8296 59.0173 67.7941 57.0527C68.4489 56.3978 69.5146 56.3979 70.1694 57.0527L74.92 61.8032C75.5748 62.4581 75.5748 63.5236 74.92 64.1786C71.6456 67.4529 66.3179 67.4529 63.0435 64.1786L44.0411 45.1762C40.759 41.8941 40.759 36.5819 44.0411 33.2998C44.6976 32.6433 45.7599 32.6434 46.4164 33.2998L51.1669 38.0503C51.8218 38.7052 51.8218 39.7707 51.1669 40.4257C49.2023 42.3903 49.2023 45.5869 51.1669 47.5516L60.6682 57.0527Z"
                  fill="white"
                />
              </g>
              <defs>
                <linearGradient
                  id="paint0_linear_80_166"
                  x1="126.827"
                  y1="-29.8009"
                  x2="148.91"
                  y2="35.8439"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="0.270846" stopColor="#4396FF" />
                  <stop offset="0.569976" stopColor="#F3E0F1" />
                  <stop offset="1" stopColor="#DFA8B4" />
                </linearGradient>
                <linearGradient
                  id="paint1_linear_80_166"
                  x1="149.005"
                  y1="-29.8009"
                  x2="171.088"
                  y2="35.8439"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="0.270846" stopColor="#4396FF" />
                  <stop offset="0.569976" stopColor="#F3E0F1" />
                  <stop offset="1" stopColor="#DFA8B4" />
                </linearGradient>
                <clipPath id="clip0_80_166">
                  <rect
                    width="43"
                    height="43"
                    fill="white"
                    transform="translate(37 27)"
                  />
                </clipPath>
              </defs>
            </svg>
            <div className="text-[16px] text-white leading-7">
              Jeśli jakiś temat nie daje Ci spokoju, jakieś marzenie czeka na
              realizację, a Ty czujesz, że utknąłeś w swoich działaniach -
              napisz lub zadzwoń.
            </div>
          </div>
        </div>
        <div className="flex flex-col xl:flex-row gap-4">
          <div className="xl:w-[30%] h-[340px] flex flex-col gap-4 bg-[#FF99BE] rounded-[35px] px-4 py-8">
            <svg
              width="120"
              height="98"
              viewBox="0 0 120 98"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="48.8596"
                cy="48.8596"
                r="47.3596"
                stroke="url(#paint0_linear_80_166)"
                strokeWidth="3"
              />
              <circle
                cx="71.0371"
                cy="48.8596"
                r="47.3596"
                stroke="url(#paint1_linear_80_166)"
                strokeWidth="3"
              />
              <g clipPath="url(#clip0_80_166)">
                <path
                  d="M65.4297 61.7923L46.4274 42.79C43.2935 39.6561 42.6552 34.9716 44.5125 31.2061C43.9046 31.3382 43.326 31.6396 42.8535 32.1121C38.918 36.0476 38.918 42.4284 42.8535 46.3638L61.8558 65.3662C65.7913 69.3017 72.1721 69.3017 76.1075 65.3662C76.5801 64.8937 76.8815 64.3151 77.0136 63.7072C73.2481 65.5644 68.5637 64.9263 65.4297 61.7923Z"
                  fill="#FF99BE"
                />
                <path
                  d="M75.1732 32.0388C75.1732 29.2604 72.9128 27 70.1345 27C67.3561 27 65.0957 29.2604 65.0957 32.0388C65.0957 34.8171 67.3561 37.0775 70.1345 37.0775C72.9128 37.0775 75.1732 34.8171 75.1732 32.0388ZM70.1345 33.7183C69.2083 33.7183 68.4549 32.9649 68.4549 32.0388C68.4549 31.1126 69.2083 30.3592 70.1345 30.3592C71.0606 30.3592 71.814 31.1126 71.814 32.0388C71.814 32.9649 71.0606 33.7183 70.1345 33.7183Z"
                  fill="white"
                />
                <path
                  d="M78.318 44.6542L75.9982 40.5149C74.8101 38.3946 72.5632 37.0774 70.1345 37.0774C67.7057 37.0774 65.4588 38.3946 64.2707 40.5149L61.9509 44.6542C61.3243 45.7724 62.1343 47.1549 63.4161 47.1549H76.8528C78.1346 47.1549 78.9446 45.7724 78.318 44.6542ZM66.2827 43.7957L67.2011 42.1571C67.2011 42.1571 67.2011 42.1571 67.2011 42.157C67.7957 41.0958 68.9198 40.4366 70.1345 40.4366C71.3491 40.4366 72.4732 41.0958 73.0679 42.1571L73.9862 43.7957H66.2827Z"
                  fill="white"
                />
                <path
                  d="M53.5423 35.675L48.7917 30.9244C46.8271 28.9598 43.6304 28.9598 41.6659 30.9244C37.0818 35.5085 37.0818 42.9674 41.6659 47.5515L60.6682 66.5538C65.2631 71.1488 72.7003 71.1488 77.2952 66.5538C79.2646 64.5845 79.2646 61.3973 77.2952 59.4279L72.5447 54.6774C70.5801 52.7127 67.3834 52.7127 65.4188 54.6774C64.7639 55.3323 63.6984 55.3323 63.0435 54.6774L53.5424 45.1762C52.8875 44.5214 52.8875 43.4558 53.5424 42.801C55.5069 40.8363 55.5069 37.6396 53.5423 35.675ZM60.6682 57.0527C62.633 59.0174 65.8296 59.0173 67.7941 57.0527C68.4489 56.3978 69.5146 56.3979 70.1694 57.0527L74.92 61.8032C75.5748 62.4581 75.5748 63.5236 74.92 64.1786C71.6456 67.4529 66.3179 67.4529 63.0435 64.1786L44.0411 45.1762C40.759 41.8941 40.759 36.5819 44.0411 33.2998C44.6976 32.6433 45.7599 32.6434 46.4164 33.2998L51.1669 38.0503C51.8218 38.7052 51.8218 39.7707 51.1669 40.4257C49.2023 42.3903 49.2023 45.5869 51.1669 47.5516L60.6682 57.0527Z"
                  fill="white"
                />
              </g>
              <defs>
                <linearGradient
                  id="paint0_linear_80_166"
                  x1="126.827"
                  y1="-29.8009"
                  x2="148.91"
                  y2="35.8439"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="0.270846" stopColor="#4396FF" />
                  <stop offset="0.569976" stopColor="#F3E0F1" />
                  <stop offset="1" stopColor="#DFA8B4" />
                </linearGradient>
                <linearGradient
                  id="paint1_linear_80_166"
                  x1="149.005"
                  y1="-29.8009"
                  x2="171.088"
                  y2="35.8439"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="0.270846" stopColor="#4396FF" />
                  <stop offset="0.569976" stopColor="#F3E0F1" />
                  <stop offset="1" stopColor="#DFA8B4" />
                </linearGradient>
                <clipPath id="clip0_80_166">
                  <rect
                    width="43"
                    height="43"
                    fill="white"
                    transform="translate(37 27)"
                  />
                </clipPath>
              </defs>
            </svg>
            <div className="text-[25px] text-white font-bold">
              Trening Mentalny - dla kogo?
            </div>
          </div>
          <div className="xl:w-[70%] h-[340px] rounded-[35px] px-4 py-8 flex flex-col justify-center gap-4 bg-[#FF99BE]">
            <div className="text-white">
              ⦁ Każdego kto chce zrealizować swoje cele
            </div>
            <div className="text-white">
              ⦁ Potrzebuje wsparcia na drodze rozwoju
            </div>
            <div className="text-white">
              ⦁ Pragnie budować swoją pewność siebie
            </div>
            <div className="text-white">⦁ Szuka swojej drogi zawodowej</div>
            <div className="text-white">
              ⦁ Nie potrafi radzić sobie ze stresem i trudnymi sytuacjami
            </div>
            <div className="text-white">
              ⦁ Pragnie być szczęśliwym i zadowolonym z życia
            </div>
          </div>
        </div>
      </div>
      <div
        className="bg-white rounded-t-[50px] flex flex-col justify-center items-center gap-8 py-12 mt-12 h-[50px]"
        id="offer"
      />
    </section>
  );
}
