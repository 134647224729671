import createStyleForBackgroundImage from '@/utils/createStyleForBackgroundImage';
import { defaultStyles } from '@/utils/defaultStyles';
import { ModalButton } from './Navbar';

export default function HeroSection() {
  return (
    <section
      className={`flex flex-col`}
      style={createStyleForBackgroundImage('/hero-bg.png', {
        withDarkOverlay: false,
        backgroundPosition: '50% 120%',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      })}
    >
      <div className={`z-10 ${defaultStyles.paddingBig}`}>
        <div
          className={`flex flex-col items-start xl:flex-row xl:items-end justify-between gap-8 ${defaultStyles.padding}`}
        >
          <div className="flex flex-col gap-6 self-start py-12">
            <div className="text-white text-[35px] 2xl:text-[40px] font-bold">
              “Jeśli potrafisz o czymś marzyć, to potrafisz także tego dokonać”
            </div>
            <div className="text-[25px] font-bold text-white">
              - Walt Disney
            </div>
            <div className="text-white text-[16px] xl:w-[90%] leading-6">
              Twoje marzenie, może być pięknym celem do zrealizowania, zrób ze
              mną pierwszy krok, a pokaże Ci jak tego dokonać.
            </div>
            <ModalButton secondary />
            <div
              className="text-[25px] font-bold text-white cursor-pointer"
              onClick={() =>
                document
                  .getElementById('contact')!
                  .scrollIntoView({ behavior: 'smooth' })
              }
            >
              Bezpłatna konsultacja
            </div>
          </div>
          <img
            alt="Patryk Gąsiorek"
            loading="lazy"
            className="w-[90%] lg:w-[70%] xl:w-[50%] self-center xl:self-end"
            src="/anna-hero.png"
          />
        </div>
      </div>
      <div
        className={`flex flex-col justify-center z-20 px-0 lg:px-24 xl:px-32 2xl:px-52`}
        id="services"
      >
        <div
          className={`flex flex-col justify-center items-center gap-4 bg-white rounded-t-[75px] py-12 ${defaultStyles.padding}`}
        >
          <div className="text-[35px] text-black font-bold">Moje usługi</div>
        </div>
      </div>
    </section>
  );
}
