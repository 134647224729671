import { defaultStyles } from '@/utils/defaultStyles';

export default function TrainingRoleSection() {
  return (
    <section className={`flex flex-col bg-white ${defaultStyles.paddingBig}`}>
      <div className={`flex flex-row justify-between ${defaultStyles.padding}`}>
        <div className="flex flex-col 2xl:flex-row gap-8 2xl:gap-4 justify-between">
          <div className="flex flex-col gap-8">
            <div className="text-[35px] text-black font-bold">
              Przewodniczka
              <br /> Życiowych Wyzwań
            </div>
            <div className="text-[16px] text-black">
              Cześć! Nazywam się Anna Jaworska i uwielbiam swoją pracę.
              <br />
              <br />
              Jeśli brzmi to jak wstęp z grupy wsparcia, to dobrze trafiłeś.
              Pomagam w odkrywaniu wewnętrznego potencjału, radości z życia i
              dokonywaniu dobrych życiowych wyborów.
              <br />
              <br />
              Czy zawsze wiedziałam co chcę robić w życiu? <br />
              <br />
              Nie, moja droga zawodowa to kręta ścieżka z przeszkodami, wzloty i
              upadki, radości i kryzysy. Dzięki mentorom - tym którzy przeszli
              tą drogę przede mną, wiedziałam jak ważne jest określenie celu
              swoich działań. Znalezienie iskierki, która rozpali ogień
              wewnętrznej siły i odwagi. <br />
              <br />
              Przez 12 lat pracowałam w różnych branżach, zdobywając niezbędne
              doświadczenie, aby usłyszeć ludzi i ich potrzeby. Od 8 lat
              wykorzystuję wiedzę, praktykę i narzędzia trenerskie prowadząc
              tematyczne warsztaty mentalne dla różnych grup wiekowych oraz
              spotkania indywidualne.
              <br />
              <br />
              Nie ważne czy masz 18, 45 czy 60 lat iskierka zmiany czeka, aby
              zapłonąć, nie pozwól jej zgasnąć.
            </div>
          </div>
          <img
            alt="Galeria"
            loading="lazy"
            className="w-[90%] md:w-[70%] lg:w-[60%] 2xl:w-[50%]"
            src="/training-role.png"
          />
        </div>
      </div>
    </section>
  );
}
