import CardsSection from '@/components/CardsSection';
import ContactFormSection from '@/components/ContactFormSection';
import HeroSection from '@/components/HeroSection';
import MeSection from '@/components/MeSection';
import PricingSection from '@/components/PricingSection';
import ServicesSection from '@/components/ServicesSection';
import TrainingRoleSection from '@/components/TrainingRoleSection';
import Head from 'next/head';

export default function Home() {
  return (
    <>
      <Head>
        <title>Anna Jaworska</title>
        <meta name="description" content="Strona główna Anna Jaworska" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <HeroSection />
      <ServicesSection />
      {/* <TrainingRoleSection /> */}
      <MeSection />
      <TrainingRoleSection />
      <CardsSection />
      <PricingSection />
      <ContactFormSection />
    </>
  );
}
