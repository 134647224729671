export function FormField(props: {
  placeholder: string;
  onChange: (value: string) => void;
  value: string | undefined;
  type?: string;
  min?: number;
  max?: number;
}) {
  return (
    <input
      type={props.type ?? 'text'}
      placeholder={props.placeholder}
      onChange={(e) => props.onChange(e.currentTarget.value)}
      value={props.value}
      min={props.min}
      max={props.max}
      className="bg-transparent border-[#D6D6D6] border-t-0 border-l-0 border-r-0 outline-none underline-none p-2 flex-grow"
    />
  );
}
