import { defaultStyles } from '@/utils/defaultStyles';
import CertsSection from './CertsSection';

export default function MeSection() {
  return (
    <section
      className={`flex flex-col bg-[#F1F6FA] ${defaultStyles.paddingBig}`}
    >
      <div className="bg-white rounded-b-[50px] flex flex-col justify-center items-center gap-8 py-14" />
      <div className="py-24">
        <CertsSection />
      </div>
      <div
        className="bg-white rounded-t-[50px] flex flex-col justify-center items-center gap-8 py-12 h-[50px]"
        id="about-me"
      />
    </section>
  );
}
